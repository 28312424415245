import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities, getListingsById } from '../../ducks/marketplaceData.duck';
import { loadData } from './LandingPage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionListings,
  SectionFollowUs,
  SectionUserProfiles,
  SectionPayments,
  SectionAbout,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/saunatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/saunatimeTwitter-600x314.jpg';
import css from './LandingPage.module.css';
import { addToWishList } from '../WishListPage/WishListPage.duck';

const { UUID } = sdkTypes;

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    userProfiles,
    usersLoaded,
    fetchUsersInProgress,
    fetchUsersError,
    listings,
    listingsLoaded,
    fetchListingsInProgress,
    fetchListingsError,
    onAddToWishList,
    addToWishListInProgress,
    addToWishListError,
    currentWishListListingId,
    currentUser,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.main}>
          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} />
          </div>
          <ul className={css.sections}>
            {listingsLoaded ? (
              <li className={css.section}>
                <div className={css.sectionContentFirstChild}>
                  <SectionListings
                    intl={intl}
                    listings={listings}
                    fetchListingsInProgress={fetchListingsInProgress}
                    fetchListingsError={fetchListingsError}
                    onAddToWishList={onAddToWishList}
                    currentWishListListingId={currentWishListListingId}
                    addToWishListInProgress={addToWishListInProgress}
                    addToWishListError={addToWishListError}
                    currentUser={currentUser}
                  />
                </div>
              </li>
            ) : null}
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionFollowUs intl={intl} />
              </div>
            </li>
            {usersLoaded ? (
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionUserProfiles
                    fetchUsersInProgress={fetchUsersInProgress}
                    fetchUsersError={fetchUsersError}
                    userProfiles={userProfiles}
                  />
                </div>
              </li>
            ) : null}
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionPayments />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionAbout />
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    userIds,
    fetchUsersInProgress,
    fetchUsersError,
    listingsResultIds,
    fetchListingsInProgress,
    fetchListingsError,
  } = state.LandingPage;
  const {
    currentWishListListingId,
    addToWishListInProgress,
    addToWishListError,
  } = state.WishListPage;

  const { currentUser } = state.user;

  const userProfiles = userIds.map(id => {
    return getMarketplaceEntities(state, [{ type: 'user', id: new UUID(id) }]);
  });
  const usersLoaded = userProfiles && userProfiles.length > 0;

  const listings = getListingsById(state, listingsResultIds);
  const listingsLoaded = listings && listings.length > 0;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    userProfiles,
    usersLoaded,
    fetchUsersInProgress,
    fetchUsersError,
    listings,
    listingsLoaded,
    fetchListingsInProgress,
    fetchListingsError,
    addToWishListInProgress,
    addToWishListError,
    currentWishListListingId,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onAddToWishList: listingId => dispatch(addToWishList(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

LandingPage.loadData = loadData;

export default LandingPage;
